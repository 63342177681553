import {area} from 'd3-shape';
import {calculateBandLevels} from './utils/compare';

const SafeBand = ({bars, xScale, yScale}: any) => {
  const bandLevels = calculateBandLevels([
    {
      id: bars[0]?.data?.data?.chemical,
      color: bars[0]?.data?.data?.color,
      unit: bars[0]?.data?.data?.unit,
      data: [],
    },
  ]);
  const areaGenerator = area()
      .x((bar: any) => xScale(bar.data.indexValue) + bar.width / 2)
      .y0(() => yScale(bandLevels.min))
      .y1(() => yScale(bandLevels.max));

  return (
    <path
      d={`${areaGenerator([...bars])}`}
      fill="#7271712b"
      fillOpacity={1}
      stroke="#7271712b"
      strokeWidth={1}
    />
  );
};

export default SafeBand;
