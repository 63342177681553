import request from './request';
import {TraitNameType} from 'utils/traits';

export const fetchCentersOverview = (
    centerIds: string[],
    traitName: TraitNameType,
) => {
  const params = {
    trait: traitName.toLocaleLowerCase(),
    center_ids: JSON.stringify(centerIds),
  };

  return request.request<any>({
    url: '/centers/overviews',
    method: 'GET',
    params,
  });
};
