export const handleErrorMessage = (err: any, toast: boolean = false) => {
  const errMessage =
    err?.response?.data?.message || err?.message || 'An error has occurred!';
  if (!toast) {
    return errMessage;
  }
};

/**
 * Gets time in 12hours format
 */
export const get12hourTime = (date = new Date()) => {
  const [hrs, min] = date.toLocaleTimeString().split(':');
  const _hr = Math.abs;
  if (+hrs > 11) return `${_hr(+hrs > 12 ? +hrs - 12 : +hrs)}:${min}PM`;
  return `${_hr(+hrs === 0 ? 12 : +hrs)}:${min}AM`;
};

export const representTableData = <T>(
  data: T[],
  reshape?: (curObject: T, i: number) => T | object,
) => {
  if (data?.length) {
    return data.reduce((res: any[], _cur, i) => {
      if (reshape) {
        const overwrite = reshape(_cur, i);
        res.push({sn: i + 1, ..._cur, ...overwrite});
      } else res.push({sn: i + 1, ..._cur});
      return res;
    }, []);
  }
  return data;
};

export const shapeData = (arr: any[]) => {
  return arr.reduce((res, cur) => {
    if (cur.issue) res.push({...cur, color: '#F4A335'});
    else {
      res.push({
        ...cur,
        color: '#0088b2',
      });
    }
    return res;
  }, []);
};

const getStorage = () => {
  const rememeberUser = localStorage.getItem('rememberVarunaUser');

  if (rememeberUser) {
    return localStorage;
  }
  return sessionStorage;
};

export const storage = getStorage();

export const getScoreByChemical = (chemical: string, measure: number) => {
  switch (chemical) {
    case 'turbidity':
      if (measure === 0) {
        return 2;
      } else if (measure > 20) {
        return -2;
      } else if (measure > 10) {
        return -1;
      } else {
        return 1;
      }
    case 'chlorine':
      if (measure > 2 || measure < 0.2) {
        return -2;
      } else if (measure > 0.5) {
        return 1;
      } else if (measure > 0.2) {
        return 2;
      } else {
        return 2;
      }
    case 'conductivity':
      if (measure < 200) {
        return 1;
      } else if (measure > 800) {
        return -1;
      } else {
        return 2;
      }
    case 'ph':
      if (measure > 8.5) {
        return -2;
      } else if (measure < 6.5) {
        return 0;
      } else if (measure === 7) {
        return 2;
      } else {
        return 1;
      }
    case 'water temperature':
      if (measure > 80) {
        return -2;
      } else if (measure > 72) {
        return -1;
      } else if (measure < 50) {
        return 0;
      } else {
        return 2;
      }
    default:
      return 0;
  }
};

export const getGrade = (quality: number) => {
  if (quality < 25) {
    return 'Poor';
  } else if (quality < 50) {
    return 'Below Average';
  } else if (quality < 75) {
    return 'Average';
  } else if (quality < 100) {
    return 'Good';
  } else {
    return 'Excellent';
  }
};

export const convertFromCelciusToFarenheight = (value: number) => {
  return Number(value * (9 / 5) + 32).toFixed(2);
};

export const getStatus = (chemical: string, value: number) => {
  switch (chemical) {
    case 'chlorine':
      if (value < 0.2) {
        return 'Low';
      } else if (value > 2) {
        return 'High';
      } else if (value) {
        return 'In Band';
      } else {
        return 'No Data';
      }
    case 'water temperature':
      if (value < 50) {
        return 'Cold';
      } else if (value > 72) {
        return 'Warm';
      } else if (value) {
        return 'In Band';
      } else {
        return 'No Data';
      }
    case 'conductivity':
      if (value > 800) {
        return 'High';
      } else if (value < 200) {
        return 'Low';
      } else if (value) {
        return 'In Band';
      } else {
        return 'No Data';
      }
    case 'turbidity':
      if (value > 20) {
        return 'Alert';
      } else if (value > 10) {
        return 'High';
      } else if (value) {
        return 'In Band';
      } else {
        return 'No Data';
      }
    case 'ph':
      if (value > 8.5) {
        return 'High(basic)';
      } else if (value < 6.5) {
        return 'Low(acidic)';
      } else if (value) {
        return 'In Band';
      } else {
        return 'No Data';
      }
    default:
      return 'Received';
  }
};
