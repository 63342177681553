import Typography from 'legacy/components/elements/typography';
import {representTableData} from 'legacy/util';
import Table from 'legacy/components/table';
import {getStatus} from 'legacy/reusables/DashboardHomePage/DashboardCompareCard/statusBadge';
import {ICenter} from 'models/center';

interface Ilist {
  number: string;
  location: string;
  score: 0 | 1 | 2;
}
const heading: Array<{
  title: string;
  description: string;
}> = [
  {
    title: 'Location',
    description:
      'This section displays a list of all locations and the status of each.',
  },
  {title: 'Number', description: ''},
  {title: 'Status', description: ''},
];

const getSensorData = (list: Ilist[]) =>
  representTableData(list, (res) => {
    return {
      Location: <Typography className="table-text">{res.location}</Typography>,
      Number: <Typography className="table-text">{res.number}</Typography>,
      Status: getStatus(res.score, 'status-icon'),
    };
  });

const Sensors = ({centers}: {centers: ICenter[]}) => {
  const tableList: Ilist[] = centers.map((center) => ({
    number: '12345678',
    location: center.name,
    score: 2,
  }));

  return (
    <div className="v-card-table mt-9">
      <Table heading={heading} columns={getSensorData(tableList)} />
    </div>
  );
};

export default Sensors;
