import {useState, useContext, createContext, ReactNode} from 'react';
import {IUser} from 'models/user';

interface IUserStateContext {
  user: IUser | null;
  token: string | null;
  setUser: (user: IUser | null) => void,
  setToken: (token: string | null) => void,
};

export const userStateContextDefault: IUserStateContext = {
  user: null,
  token: null,
  setUser: () => {},
  setToken: () => {},
};

export const UserStateContext = createContext(userStateContextDefault);

export const useUserState = () => useContext(UserStateContext);

/*
Refetch payload associated with the token. If token is invalid, clear
the localstorage and return null for cachedToken and cachedUser. But if
token is valid, then return the token as cachedToken and new payload returned
as the cachedUser. Ensure to not do any redirect in this provider
*/
const getCachedData = (): {cachedToken: string | null, cachedUser: IUser} => ({
  cachedToken: localStorage.getItem('token') || null,
  cachedUser: JSON.parse(localStorage.getItem('user') || 'null'),
});

const UserStateProvider = ({children}:{children: ReactNode}) => {
  const {cachedUser, cachedToken} = getCachedData();

  const [user, setUser] = useState<IUser | null>(cachedUser);
  const [token, setToken] = useState<string | null>(cachedToken);

  const value: IUserStateContext = {
    user,
    token,
    setUser,
    setToken,
  };

  return (
    <UserStateContext.Provider value={value}>
      {children}
    </UserStateContext.Provider>
  );
};

export default UserStateProvider;
