import {useState, useContext, createContext, ReactNode, useEffect} from 'react';
import {MapType} from 'utils/types';
import {IFetchInsightsResponseData, IInsight} from 'models/insight';
import {IFetchTelemetriesResponseData, ITelemetry} from 'models/telemetry';
import {IntervalType} from 'utils/intervals';
import {traits, TraitNameType} from 'utils/traits';
import {fetchTelemetries} from 'api/telemetry';
import {fetchInsights} from 'api/insight';
import {useProfileState} from './profileStateProvider';
import {fetchCentersOverview} from 'api/center';

interface IDashboardHomeStateContext {
  selectedCenterId: string | null,
  selectedInterval: IntervalType | null,
  selectedTraitNames: TraitNameType[],
  selectedOverviewTraitName: TraitNameType | null,
  telemetries: ITelemetry[],
  insights: IInsight[],
  overviews: MapType<number>,
  setSelectedCenterId: (centerId: string | null) => void,
  setSelectedInterval: (interval: IntervalType | null) => void,
  setSelectedTraitNames: (traitNames: TraitNameType[]) => void,
  setSelectedOverviewTraitName: (traitName: TraitNameType) => void,
};

export const dashboardHomeStateContextDefault: IDashboardHomeStateContext = {
  selectedCenterId: null,
  selectedInterval: null,
  selectedTraitNames: [],
  selectedOverviewTraitName: null,
  telemetries: [],
  insights: [],
  overviews: {},
  setSelectedCenterId: (centerId: string | null) => {},
  setSelectedInterval: (interval: IntervalType | null) => {},
  setSelectedTraitNames: (traitNames: TraitNameType[]) => {},
  setSelectedOverviewTraitName: (traitName: TraitNameType) => {},
};

export const DashboardHomeStateContext =
    createContext(dashboardHomeStateContextDefault);

export const useDashboardHomeState =
    () => useContext(DashboardHomeStateContext);

const DashboardHomeStateProvider = ({children}: {children: ReactNode}) => {
  const {centers} = useProfileState();
  const [insights, setInsights] = useState<IInsight[]>([]);
  const [overviews, setOverviews] = useState<MapType<number>>({});
  const [telemetries, setTelemetries] = useState<ITelemetry[]>([]);
  const [selectedCenterId, setSelectedCenterId] = useState<string | null>(null);
  const [selectedInterval, setSelectedInterval] =
      useState<IntervalType | null>(null);
  const [selectedTraitNames, setSelectedTraitNames] =
      useState<TraitNameType[]>(Object.keys(traits) as TraitNameType[]);
  const [selectedOverviewTraitName, setSelectedOverviewTraitName] =
      useState<TraitNameType | null>(null);

  useEffect(() => {
    if (!selectedCenterId && !!centers.length) {
      setSelectedCenterId(centers[0].id);
    }
  }, [centers, selectedCenterId]);

  useEffect(() => {
    setTelemetries([]);
    setInsights([]);
    setTelemetries([]);
    if (!!selectedCenterId && !!selectedInterval) {
      fetchTelemetries(selectedCenterId, selectedInterval).then((response) => {
        const {data: {telemetries}} = (
          response as {data: IFetchTelemetriesResponseData}
        );
        setTelemetries(telemetries);
      });

      fetchInsights(selectedCenterId, selectedInterval).then((response) => {
        const {data: {insights}} = (
          response as {data: IFetchInsightsResponseData}
        );
        setInsights(insights);
      });
    }
  }, [selectedCenterId, selectedInterval]);

  useEffect(() => {
    setOverviews({});

    if (!!centers.length && !!selectedOverviewTraitName) {
      const centerIds = centers.map((c) => c.id);

      fetchCentersOverview(
          centerIds,
          selectedOverviewTraitName,
      ).then((response) => {
        const {data: {overviews}} = response as {data: any};

        setOverviews(overviews);
      });
    }
  }, [centers, selectedOverviewTraitName]);

  const value: IDashboardHomeStateContext = {
    selectedCenterId,
    selectedInterval,
    selectedTraitNames,
    selectedOverviewTraitName,
    telemetries,
    insights,
    overviews,
    setSelectedCenterId,
    setSelectedInterval,
    setSelectedTraitNames,
    setSelectedOverviewTraitName,
  };

  return (
    <DashboardHomeStateContext.Provider value={value}>
      {children}
    </DashboardHomeStateContext.Provider>
  );
};

export default DashboardHomeStateProvider;
