import {Iband} from 'legacy/model/compare';
import {IChartData} from '../compareChart';

export const getAxisFormat = (interval: string) => {
  switch (interval) {
    case 'Last 1 hour':
      return {
        format: '%I:%M%p',
        tickRotation: -50,
      };
    case 'Last 2 hours':
      return {
        format: '%I:%M%p',
        tickRotation: -50,
      };
    case 'Last 4 hours':
      return {
        format: '%I:%M%p',
        tickRotation: -50,
      };
    case 'Last 8 hours':
      return {
        format: '%I:%M%p',
        tickRotation: -50,
      };
    case 'Last 12 hours':
      return {
        format: '%I:%M%p',
        tickRotation: -50,
      };
    case 'Last 24 hours':
      return {
        format: '%I %p',
        tickRotation: -50,
      };
    case 'Last 15 days':
      return {
        format: '%m/%d',
        tickRotation: -50,
      };
    case 'Last 7 days':
      return {
        format: '%a',
        tickRotation: -50,
      };
    case 'Last 2 days':
      return {
        format: '%a %I:%M%p',
        tickRotation: -30,
      };
    case 'Last 3 days':
      return {
        format: '%a %I:%M%p',
        tickRotation: -30,
      };
    case 'Last 30 days':
      return {
        format: '%m/%d',
        tickRotation: -50,
      };
    case 'Last 2 months':
      return {
        format: '%m/%d',
        tickRotation: -50,
      };

    case 'Last 3 month':
      return {
        format: '%m/%d',
        tickRotation: -50,
      };

    case 'Last 12 month':
      return {
        format: '%b',
        tickRotation: -50,
      };
    default:
      return {
        format: '%I:%M%p',
        tickRotation: -50,
      };
  }
};

export const calculateBandLevels = (series: IChartData[]) => {
  const temperatureData: any = series.find(
      (data: IChartData) => data.id === 'temperature',
  );
  const turbidityData: any = series.find(
      (data: IChartData) => data.id === 'turbidity',
  );
  const phData: any = series.find(
      (data: IChartData) => data.id === 'ph',
  );
  const chlorineData: any = series.find(
      (data: IChartData) => data.id === 'chlorine',
  );
  const pressureData: any = series.find(
      (data: IChartData) => data.id === 'pressure',
  );

  const BandLevels: Iband = {
    max: 120,
    min: 0.2,
  };

  // Standard Ranges

  if (pressureData) {
    BandLevels.max = 80;
  } else if (temperatureData) {
    BandLevels.max = 25;
  } else if (phData) {
    BandLevels.max = 8.5;
  } else if (chlorineData) {
    BandLevels.max = 2;
  } else if (turbidityData) {
    BandLevels.max = 1;
  } else {
    BandLevels.max = 2;
  }

  if (turbidityData) {
    BandLevels.min = 0;
  } else if (chlorineData) {
    BandLevels.min = 0.2;
  } else if (phData) {
    BandLevels.min = 6.5;
  } else if (pressureData) {
    BandLevels.min = 35;
  } else if (temperatureData) {
    BandLevels.min = 4;
  } else {
    BandLevels.min = 200;
  }

  // Amarillo Data
  // if (conductivityData) {
  //   BandLevels.max = 1000;
  // } else if (pressureData) {
  //   BandLevels.max = 80;
  // } else if (waterTemperatureData) {
  //   BandLevels.max = 30;
  // } else if (phData) {
  //   BandLevels.max = 14;
  // } else if (chlorineData) {
  //   BandLevels.max = 2;
  // } else if (turbidityData) {
  //   BandLevels.max = 20;
  // } else {
  //   BandLevels.max = 2;
  // }

  // if (turbidityData) {
  //   BandLevels.min = 0;
  // } else if (chlorineData) {
  //   BandLevels.min = 0;
  // } else if (phData) {
  //   BandLevels.min = 0;
  // } else if (pressureData) {
  //   BandLevels.min = 0;
  // } else if (waterTemperatureData) {
  //   BandLevels.min = 0;
  // } else {
  //   BandLevels.min = 0;
  // }

  return BandLevels;
};

export const getMaxMargin = (data: IChartData[]) => {
  const chemicals = data.map((chemicalData) => chemicalData.id);

  if (chemicals.includes('pressure')) {
    return 40;
  } else if (chemicals.includes('temperature')) {
    return 20;
  } else if (chemicals.includes('ph')) {
    return 2;
  } else if (chemicals.includes('chlorine')) {
    return 2;
  } else if (chemicals.includes('turbidity')) {
    return 0.5;
  } else {
    return 0.2;
  }
};
