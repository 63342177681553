import request from './request';
import {IntervalType} from 'utils/intervals';
import {IFetchInsightsResponseData} from 'models/insight';

export const fetchInsights = (centerId: string, interval: IntervalType) => {
  const params = {
    center_ids: JSON.stringify([centerId]),
    recorded_at_start: interval.getStartDate().toISOString(),
    recorded_at_end: interval.getEndDate().toISOString(),
  };

  return request.request<IFetchInsightsResponseData>({
    url: '/insights/',
    method: 'GET',
    params,
  });
};
