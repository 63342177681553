import {ResponsiveLine, Layer} from '@nivo/line';
import ToolTip from './tooltip';
import SafeBand from './compareChartSafeBand';
import {theme} from './utils/constant';
import {
  getMaxMargin,
  getAxisFormat,
  calculateBandLevels,
} from './utils/compare';
import {Iband} from 'legacy/model/compare';
import {TraitNameType} from 'utils/traits';
// import moment from 'moment';

export interface IChartDataPoint {
  x: string,
  y: number,
}

export interface IChartData {
  id: TraitNameType;
  color: string;
  unit: string;
  data: IChartDataPoint[],
}

export const CompartChart = (
    {data, interval}: {data: IChartData[], interval: string},
) => {
  const showBand = data.length === 1;
  const bandLevel: Iband = calculateBandLevels(data);
  const bandLevelMAx: number = bandLevel.max + getMaxMargin(data);
  const bandLevelMin: number = 0;
  const standardizedBandLevelMin: number = bandLevelMin > 0 ? 0 : bandLevelMin;

  const Layers: Layer[] | undefined = [
    SafeBand,
    'grid',
    'markers',
    'axes',
    'areas',
    'crosshair',
    'lines',
    'points',
    'slices',
    'mesh',
    'legends',
  ];

  return (
    <ResponsiveLine
      margin={{bottom: 50, right: 6, left: 45, top: 6}}
      xFormat="time:%Y-%m-%d %H:%M"
      colors={(d) => d.color}
      axisTop={{tickValues: []}}
      axisRight={{tickValues: []}}
      axisBottom={getAxisFormat(interval)}
      enableGridY={false}
      areaOpacity={0.3}
      theme={theme}
      enableSlices={false}
      data={data}
      yScale={{
        type: 'linear',
        stacked: false,
        reverse: false,
        max: bandLevelMAx,
        min: standardizedBandLevelMin,
      }}
      curve="monotoneX"
      xScale={{
        type: 'time',
        format: '%Y-%m-%d %H:%M',
        precision: 'minute',
      }}
      layers={showBand ? Layers : undefined}
      pointSize={10}
      pointColor="white"
      pointBorderWidth={2}
      pointBorderColor={{from: 'serieColor'}}
      useMesh={true}
      tooltip={(point) => <ToolTip point={point} data={data} />}
    />
  );
};

export default CompartChart;
