import {getNow, getDeltaFromNow} from './dateAndTime';

export type IntervalType = {
  label: string,
  getStartDate: () => Date,
  getEndDate: () => Date,
};

export const lastOneHour: IntervalType = {
  label: 'Last 1 Hour',
  getStartDate: () => getDeltaFromNow(3600),
  getEndDate: getNow,
};

export const lastTwoHours: IntervalType = {
  label: 'Last 2 Hours',
  getStartDate: () => getDeltaFromNow(3600 * 2),
  getEndDate: getNow,
};

export const lastFourHours: IntervalType = {
  label: 'Last 4 Hours',
  getStartDate: () => getDeltaFromNow(3600 * 4),
  getEndDate: getNow,
};

export const lastEightHours: IntervalType = {
  label: 'Last 8 Hours',
  getStartDate: () => getDeltaFromNow(3600 * 8),
  getEndDate: getNow,
};

export const lastTwelveHours: IntervalType = {
  label: 'Last 12 Hours',
  getStartDate: () => getDeltaFromNow(3600 * 12),
  getEndDate: getNow,
};

export const lastTwentyFourHours: IntervalType = {
  label: 'Last 24 Hours',
  getStartDate: () => getDeltaFromNow(3600 * 24),
  getEndDate: getNow,
};

export const lastTwoDays: IntervalType = {
  label: 'Last 2 Days',
  getStartDate: () => getDeltaFromNow(3600 * 24 * 2),
  getEndDate: getNow,
};

export const lastThreeDays: IntervalType = {
  label: 'Last 3 Days',
  getStartDate: () => getDeltaFromNow(3600 * 24 * 3),
  getEndDate: getNow,
};

export const lastSevenDays: IntervalType = {
  label: 'Last 7 Days',
  getStartDate: () => getDeltaFromNow(3600 * 24 * 7),
  getEndDate: getNow,
};

export const lastFifteenDays: IntervalType = {
  label: 'Last 15 Days',
  getStartDate: () => getDeltaFromNow(3600 * 24 * 15),
  getEndDate: getNow,
};

export const lastThirtyDays: IntervalType = {
  label: 'Last 30 Days',
  getStartDate: () => getDeltaFromNow(3600 * 24 * 30),
  getEndDate: getNow,
};

export const lastTwoMonths: IntervalType = {
  label: 'Last 2 Months',
  getStartDate: () => getDeltaFromNow(3600 * 24 * 30 * 2),
  getEndDate: getNow,
};

export const lastThreeMonths: IntervalType = {
  label: 'Last 3 Months',
  getStartDate: () => getDeltaFromNow(3600 * 24 * 30 * 3),
  getEndDate: getNow,
};

export const lastTwelveMonths: IntervalType = {
  label: 'Last 12 Months',
  getStartDate: () => getDeltaFromNow(3600 * 24 * 30 * 12),
  getEndDate: getNow,
};
