import React, {FC, HTMLAttributes} from 'react';
import './base.style.css';

export type TypographyPSizeT =
  | 'sm'
  | 'sub'
  | 'md'
  | 'md-1'
  | 'md-2'
  | 'md-3'
  | 'none';
export type TypographyHT = FC<
  HTMLAttributes<HTMLHeadingElement> & { level?: 1 | 2 | 3 | 4; text?: string }
>;
export type TypographyPT = FC<
  HTMLAttributes<HTMLParagraphElement> & { size?: TypographyPSizeT }
> & {
  Heading: TypographyHT;
};

const Typography: TypographyPT = ({children, size, className, ...props}) => {
  const fontSize = (size?: TypographyPSizeT) => {
    switch (size) {
      case 'sm':
        return '0.775rem';
      case 'sub':
        return '1.1rem';
      case 'md':
        return '1.25rem';
      case 'md-1':
        return '1.375rem';
      case 'md-2':
        return '1.5rem';
      case 'md-3':
        return '2rem';
      default:
        return '1rem';
    }
  };
  return (
    <p
      className={`v-typography ${className ?? 'text-textGrey'}`}
      style={{fontSize: size === 'none' ? 'inherit' : fontSize(size)}}
      {...props}
    >
      {children}
    </p>
  );
};

const Heading: TypographyHT = ({
  level = 1,
  text,
  children,
  className,
  ...props
}) => {
  const fontSize = (level: 1 | 2 | 3 | 4) => {
    switch (level) {
      case 1:
        return 'text-4xl';
      case 2:
        return 'text-3xl';
      case 3:
        return 'text-2xl';
      case 4:
        return 'text-xl';
    }
  };

  return React.createElement(
      `h${level}`,
      {
        className: `v-typography ${fontSize(level)} ${
          className ?? 'text-textGrey'
        }`,
        ...props,
      },
      text || children,
  );
};

Typography.Heading = Heading;

export default Typography;
