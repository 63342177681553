import {MapType} from 'utils/types';

export type TraitNameType = (
  'ph' |
  'chlorine' |
  'pressure' |
  'turbidity' |
  'temperature'
)

export type TraitType = {
  name: TraitNameType,
  label: string,
  unit: string | undefined,
  unitLabel: string | undefined,
  color: string,
};

export const ph: TraitType = {
  name: 'ph',
  label: 'pH',
  unit: undefined,
  unitLabel: undefined,
  color: '#D9683C',
};

export const chlorine: TraitType = {
  name: 'chlorine',
  label: 'Chlorine',
  unit: 'ppm',
  unitLabel: 'mg/L',
  color: '#00A8D7',
};

export const pressure: TraitType = {
  name: 'pressure',
  label: 'Pressure',
  unit: 'psi',
  unitLabel: 'psi',
  color: '#F4A335',
};

export const turbidity: TraitType = {
  name: 'turbidity',
  label: 'Turbidity',
  unit: 'ntu',
  unitLabel: 'ntu',
  color: '#019293',
};

export const temperature: TraitType = {
  name: 'temperature',
  label: 'Temperature',
  unit: 'celcius',
  unitLabel: '°C',
  color: '#C83D5C',
};

export const traits: MapType<TraitType> = {
  ph,
  chlorine,
  pressure,
  turbidity,
  temperature,
};
