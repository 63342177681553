const BarChartToolTip = ({data}: { data: any }) => {
  return (
    <div className="barchart-tooltip">
      <div className="barchart-tooltip-bottom" />
      <div style={{zIndex: 1, position: 'relative'}}>
        {data.label}
        <br />
        <strong>
          {data.value} {data.unit}
        </strong>
      </div>
    </div>
  );
};

export default BarChartToolTip;
