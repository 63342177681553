import request from './request';
import {IntervalType} from 'utils/intervals';
import {IFetchTelemetriesResponseData} from 'models/telemetry';

export const fetchTelemetries = (centerId: string, interval: IntervalType) => {
  const params = {
    center_ids: JSON.stringify([centerId]),
    collected_at_start: interval.getStartDate().toISOString(),
    collected_at_end: interval.getEndDate().toISOString(),
  };

  return request.request<IFetchTelemetriesResponseData>({
    url: `/telemetries/`,
    method: 'GET',
    params,
  });
};
