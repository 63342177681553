import {BarDatum, ResponsiveBar} from '@nivo/bar';
import SafeBand from './sensorLevelsSafeBand';
import {calculateBandLevels} from './utils/compare';
import BarChartToolTip from './barChartTooltip';
import {TraitNameType, traits, TraitType} from 'utils/traits';

interface BarChartProps {
  data: BarDatum[],
  traitName: TraitNameType | null,
}

export const BarChart = ({data, traitName}: BarChartProps) => {
  const trait: (TraitType | undefined) = traits[traitName || ''];

  const bandLevels = trait && data.length > 0 ?
    calculateBandLevels([
      {
        id: trait.name,
        color: trait.color,
        unit: trait.unitLabel || '',
        data: [],
      },
    ]) :
    {min: 0, max: 120};

  const maxValue =
    bandLevels.max > 700 ?
      bandLevels.max + 100 :
      bandLevels.max > 49 ?
      bandLevels.max + 50 :
      bandLevels.max > 19 ?
      bandLevels.max + 10 :
      bandLevels.max > 7.5 ?
      bandLevels.max + 5 :
      bandLevels.max > 1.5 ?
      bandLevels.max + 2.5 :
      bandLevels.max;

  return (
    <div id="bar-chart-container">
      <ResponsiveBar
        data={trait ? (data as BarDatum[]) : [{value: 0}]}
        enableGridX={true}
        keys={['value']}
        indexBy="label"
        valueScale={{type: 'linear'}}
        padding={0.05}
        margin={{
          top: 10,
          right: 10,
          bottom: 36,
          left: 46,
        }}
        maxValue={maxValue}
        enableLabel={true}
        borderRadius={2}
        axisLeft={{
          tickValues: 7,
          tickSize: 0,
          tickPadding: 5,
          format: (v) => `${v} ${trait?.unitLabel || ''}`,
        }}
        axisBottom={{tickValues: []}}
        axisTop={{tickValues: []}}
        axisRight={{tickValues: []}}
        colorBy="id"
        labelSkipHeight={7}
        colors={['#8B8E92']}
        label={(data) => `${data.data.label}`}
        labelTextColor="#FFFFFF"
        indexScale={{type: 'band', round: true}}
        layers={[SafeBand, 'grid', 'axes', 'bars', 'markers', 'legends']}
        tooltip={BarChartToolTip}
      />
    </div>
  );
};

export default BarChart;
