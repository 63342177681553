import {useContext, createContext, ReactNode} from 'react';

interface IEventSourceContext {};

export const eventSourceContextDefault: IEventSourceContext = {};

export const EventSourceContext = createContext(eventSourceContextDefault);

export const useEventSource = () => useContext(EventSourceContext);

const EventSourceProvider = ({children}:{children: ReactNode}) => {
  const value: IEventSourceContext = {};

  return (
    <EventSourceContext.Provider value={value}>
      {children}
    </EventSourceContext.Provider>
  );
};

export default EventSourceProvider;
