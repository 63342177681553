import {NavLink} from 'react-router-dom';
import logo from 'legacy/assets/images/logo.png';
import {VIconHouse, VIconDataReport} from 'legacy/assets/icons';
import RoundedImage from 'legacy/components/elements/roundedImage';

const imgStyle = {height: 30, width: 'auto'};
const logoStyle = {height: 36, width: 'auto'};

const sideBarLinks = [
  {link: '/dashboard', icon: <VIconHouse style={imgStyle} />},
  {link: '/dashboard/report', icon: <VIconDataReport style={imgStyle} />},
];

const bottomNavLinks = [
  {link: '/dashboard', icon: <VIconHouse style={imgStyle} />},
  {link: '/dashboard/report', icon: <VIconDataReport style={imgStyle} />},
];

const SideNav = () => {
  return (
    <div
      className="v-sidebar-desktop bg-bgBlack h-screen w-24"
      style={{width: 110}}
    >
      <div className="flex justify-center items-center h-20">
        <img style={logoStyle} src={logo} alt="" />
      </div>
      <div className="v-sidebar-nav flex flex-col items-center justify-between">
        <div className="flex flex-col">
          {sideBarLinks.map(({link, icon}) => (
            <div
              key={link}
              className="flex items-center w-full justify-center h-24"
            >
              <NavLink
                exact
                to={link}
                className="icon-default-side"
                activeClassName="icon-active-side"
              >
                {icon}
              </NavLink>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const BottomNav = () => {
  const image = 'https://www.pngfind.com/pngs/m/610-6104451_image-placeholder-png-user-profile-placeholder-image-png.png';

  return (
    <div className="flex justify-between items-center w-full my-2">
      {bottomNavLinks.map(({link, icon}) => (
        <div key={link} className="flex items-center justify-center">
          <NavLink exact to={link} activeClassName="icon-active-bottom">
            {icon}
          </NavLink>
        </div>
      ))}
      <div className="flex items-center justify-center">
        <RoundedImage
          src={image}
          alt="AvatarImage"
          style={{width: 25, height: 25}}
        />
      </div>
    </div>
  );
};

export default SideNav;
