import React, {FC, HTMLAttributes} from 'react';

const Card: FC<
  HTMLAttributes<HTMLDivElement> & {
    background?: string;
    noShadow?: boolean;
    maxHeight?: number | string;
  }
> = ({
  children,
  noShadow,
  style,
  maxHeight,
  background,
  className,
  ...props
}) => {
  return (
    <div
      className={`relative rounded-3xl p-8 ${
        noShadow ? '' : 'shadow'
      } min-w-min ${background ?? 'bg-white'} ${className ?? ''}`}
      style={{maxHeight: maxHeight ? maxHeight : undefined, ...style}}
      {...props}
    >
      {children}
    </div>
  );
};

export default Card;
