import {ChangeEvent, FormEvent, useState} from 'react';
import {Link, useHistory, RouteComponentProps} from 'react-router-dom';
import {doLogin} from 'api/login';
import {IDoLoginDataArg, IDoLoginResponseData} from 'models/login';
import {useUserState} from 'providers/userStateProvider';
import Input from 'legacy/components/elements/inputs';
import Button from 'legacy/components/elements/button';
import Typography from 'legacy/components/elements/typography';
import {ILoginFormState} from 'legacy/models/login';

const defaultState: ILoginFormState = {
  error: '',
  email: '',
  password: '',
  loading: false,
  emailError: false,
  passwordError: false,
};

const resetError = {
  error: '',
  loading: false,
  emailError: false,
  passwordError: false,
};

const LoginForm = () => {
  const {setUser, setToken} = useUserState();
  const history: RouteComponentProps['history'] = useHistory();
  const [state, setState] = useState(defaultState);

  const onRememberMe = (e: ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem(
        'rememberVarunaUser',
        JSON.stringify(e.target.checked),
    );
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;

    setState({
      ...state,
      ...resetError,
      [name]: value,
    });
  };

  const handleLogin = async (data: IDoLoginDataArg) => {
    setState({...state, loading: true});

    try {
      const {data: apiData}: {data: IDoLoginResponseData} = await doLogin(data);

      /** add token and user to local storage */
      localStorage.setItem('token', apiData.token);
      localStorage.setItem('user', JSON.stringify(apiData.user));

      setUser(apiData.user);
      setToken(apiData.token);

      setState({...state, loading: false});

      history.push('/dashboard');
    } catch (error: any) {
      setState({
        ...state,
        error: error?.response?.data?.detail,
      });
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = {
      email: state.email,
      password: state.password,
    };

    if (!data.email) {
      setState({
        ...state,
        error: 'Email field cannot be empty',
        emailError: true,
      });
    } else if (!data.password) {
      setState({
        ...state,
        error: 'Password field cannot be empty',
        passwordError: true,
      });
    } else {
      handleLogin(data);
    }
  };

  return (
    <>
      <form className={'grid gap-5'} onSubmit={handleSubmit}>
        <div className="error">{state.error}</div>
        <Input
          name="email"
          placeholder="Email"
          type="email"
          onChange={handleChange}
          value={state.email}
          error={state.emailError}
        />
        <Input
          name="password"
          type="password"
          placeholder="Password"
          onChange={handleChange}
          value={state.password}
          error={state.passwordError}
        />
        <div className="flex justify-between items-start mb-3">
          <Input.CheckBox label="Remember Me" onChange={onRememberMe} />
          <Button type="submit" text="LOGIN" loading={state.loading} />
        </div>
      </form>
      <div className="flex justify-end">
        <Link to="/">
          <Typography size="sm" className="text-textGrey font-bold">
            Forgot your password?
          </Typography>
        </Link>
      </div>
    </>
  );
};

export default LoginForm;
