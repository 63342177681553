import Card from 'legacy/components/elements/card';
import Typography from 'legacy/components/elements/typography';
import {VIconErrorTriangle} from 'legacy/assets/icons';
import {useDashboardHomeState} from 'providers/dashboardHomeStateProvider';
import {useProfileState} from 'providers/profileStateProvider';
import {useMemo} from 'react';
import moment from 'moment';
import {TraitNameType} from 'utils/traits';

const iconFunc = (name: string) => {
  switch (name) {
    case 'critical-issue':
      return <VIconErrorTriangle style={{width: 20, color: '#ff000'}} />;
    default:
      return <VIconErrorTriangle style={{width: 20, color: '#F4A335'}} />;
  }
};

const InsightField = ({title, value}: { title: string; value: string }) => (
  <div className="insights-field">
    {title}: <span className="insights-field-value">{value}</span>
  </div>
);

const Insights = () => {
  const {centers} = useProfileState();
  const {
    insights,
    selectedCenterId,
    selectedTraitNames,
  } = useDashboardHomeState();

  const selectedCenter = useMemo(() => {
    if (centers.length === 0) return;

    return centers.find((center) => center.id === selectedCenterId);
  }, [centers, selectedCenterId]);

  return (
    <Card maxHeight={420} className="small-card-first insights-card">
      <div className="card-header" style={{marginBottom: '1rem'}}>
        <div className={'card-header-section1'}>
          <Typography
            size="sub"
            className="text-normal-blue uppercase font-bold whitespace-nowrap"
          >
            insights
          </Typography>
        </div>
      </div>
      <div
        className="insights-table"
        style={{padding: '1rem 0', height: 'calc(100% - 50px)'}}
      >
        <div className="overflow-auto v-card-table">
          {insights
              .filter((insight) => selectedTraitNames.includes(
                insight.trait as TraitNameType,
              ))
              .map((insight, i) => (
                <div className="mb-5" key={i}>
                  <div className="flex insight-heading flex-col items-start">
                    <Typography
                      className="uppercase font-bold flex items-center"
                      style={{
                        color: insight.status === 'warning' ?
                          '#F4A335' :
                          (insight.status === 'critical-issue' ? '#ff0000' : '#74A343'),
                      }}
                    >
                      <span className={'mr-3'}>{iconFunc(insight.status)}</span> {insight.status}
                    </Typography>
                    <div className="insights-title" style={{marginTop: '1rem'}}>{insight.summary}</div>
                    <InsightField title="DATE" value={moment(new Date(insight.recorded_at)).format('MMMM D, YYYY HH:mm A')} />
                    <InsightField title="LOCATION" value={selectedCenter?.name || ''} />
                    <InsightField title="DETAILS" value={insight.description} />
                  </div>
                </div>
              ))
          }
        </div>
      </div>
    </Card>
  );
};

export default Insights;
