import {useEffect, useMemo} from 'react';
import Typography from 'legacy/components/elements/typography';
import Card from 'legacy/components/elements/card';
import MyResponsiveLine, {IChartData, IChartDataPoint} from 'legacy/components/compareChart';
import Overview from './overview';
import Dropdown from 'legacy/components/dropdown';
import StatusBadge from './statusBadge';
import {useProfileState} from 'providers/profileStateProvider';
import {useDashboardHomeState} from 'providers/dashboardHomeStateProvider';
import {MapType} from 'utils/types';
import * as intervals from 'utils/intervals';
import moment from 'moment';
import {TraitNameType, traits} from 'utils/traits';

const compareIntervals = [
  intervals.lastOneHour,
  intervals.lastTwoHours,
  intervals.lastFourHours,
  intervals.lastEightHours,
  intervals.lastTwelveHours,
  intervals.lastTwentyFourHours,
  intervals.lastTwoDays,
  intervals.lastThreeDays,
  intervals.lastSevenDays,
  intervals.lastFifteenDays,
  intervals.lastThirtyDays,
  intervals.lastTwoMonths,
  intervals.lastThreeMonths,
  intervals.lastTwelveMonths,
];

const DashboardCompareCard = () => {
  const {centers} = useProfileState();
  const {
    selectedCenterId,
    selectedInterval,
    selectedTraitNames,
    telemetries,
    setSelectedCenterId,
    setSelectedInterval,
  } = useDashboardHomeState();

  const centerNames: string[] = useMemo(
      () => centers.map((center) => center.name), [centers],
  );

  const intervalLabels: string[] = useMemo(
      () => compareIntervals.map((interval) => interval.label), [],
  );

  const centerNameToCenterIdMap: MapType<string> = useMemo(
      () => centers.reduce((result, center) => ({
        ...result,
        [center.name]: center.id,
      }), {}), [centers]);

  const intervalLabelToIntervalMap: MapType<intervals.IntervalType> = useMemo(
      () => compareIntervals.reduce((result, interval) => ({
        ...result,
        [interval.label]: interval,
      }), {}), []);

  const chartData: IChartData[] = useMemo(() => {
    const traitNameToDataPointsMap: MapType<IChartDataPoint[]> = {};

    telemetries.forEach((telemetry) => {
      if (selectedTraitNames.includes(telemetry.trait as TraitNameType)) {
        if (!(telemetry.trait in traitNameToDataPointsMap)) {
          traitNameToDataPointsMap[telemetry.trait] = [];
        }

        traitNameToDataPointsMap[telemetry.trait].push({
          x: moment(telemetry.collected_at).format('YYYY-MM-DD HH:mm'),
          // Ensure this value unit match app traits units
          y: telemetry.properties.value,
        });
      };
    });

    return Object.entries(traitNameToDataPointsMap)
        .map(([traitName, dataPoints]) => {
          const trait = traits[traitName];

          return {
            id: trait.name,
            color: trait.color,
            unit: trait.unitLabel || '',
            data: dataPoints,
          };
        });
  }, [telemetries, selectedTraitNames]);

  useEffect(() => {
    if (!selectedInterval) {
      setSelectedInterval(compareIntervals[0]);
    }
  }, [centers, selectedInterval, setSelectedInterval]);

  const handleCenterNameChange = (index: number) => {
    const centerName = centerNames[index];
    const centerId = centerNameToCenterIdMap[centerName];

    setSelectedCenterId(centerId);
  };

  const handleIntervalChange = (index: number) => {
    const intervalLabel = intervalLabels[index];
    const interval = intervalLabelToIntervalMap[intervalLabel];

    setSelectedInterval(interval);
  };

  return (
    <Card
      className="hidden md:grid v-card lg:col-start-1 lg:col-end-3 large-card compare-chart-container"
      style={{maxHeight: 'calc(100vh - 120px)'}}
    >
      <div
        className="card-header compare-card-header grid grid-template-columns12"
        style={{zIndex: 1000000}}
      >
        <div className="card-header-section1 sensor-list-dropdown">
          <Typography
            size="sub"
            className="text-normal-blue uppercase font-bold whitespace-nowrap"
          >
            LOCATION
          </Typography>

          <Dropdown
            tabIndex={1}
            className="small-screen-fill black-text"
            value={
              Object.keys(centerNameToCenterIdMap)[
                  Object.values(centerNameToCenterIdMap)
                      .indexOf(selectedCenterId as string)
              ]
            }
            list={centerNames}
            onChange={handleCenterNameChange}
          />
        </div>
        <div className="black-drop-down pl-11 flex justify-between compare-sensor-header">
          <StatusBadge score={2} />
          <div className="flex items-center compare-list-dropdown">
            <Typography
              size="sub"
              className="text-normal-blue uppercase font-bold whitespace-nowrap mr-5"
            >
              COMPARE
            </Typography>

            <Dropdown
              tabIndex={0}
              style={{maxWidth: 267, zIndex: 100000, width: 250}}
              list={intervalLabels}
              value={
                Object.keys(intervalLabelToIntervalMap)[
                    Object.values(intervalLabelToIntervalMap)
                        .indexOf(selectedInterval as intervals.IntervalType)
                ]
              }
              disabled={centers.length === 0}
              onChange={handleIntervalChange}
            />
          </div>
        </div>
      </div>
      <div className="grid grid-template-columns12">
        <div className="compare__legend flex flex-col">
          <Overview />
        </div>
        <div
          className="compare-graph compare-graph-minimal"
          style={{zIndex: 100000}}
        >
          <MyResponsiveLine data={chartData} interval={selectedInterval?.label || ''} />
        </div>
      </div>
    </Card>
  );
};

export default DashboardCompareCard;
