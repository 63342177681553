import {FullLogo} from 'legacy/assets/images';
import Card from 'legacy/components/elements/card';
import Typography from 'legacy/components/elements/typography';
import LoginForm from './LoginForm';
import RegistrationForm from './RegistrationForm';
import './base.style.css';

const AuthenticationPage = () => {
  return (
    <div className={'v-auth'}>
      <Card className={'v-login-card'}>
        <div className={'flex items-center justify-center mt-7 mb-11'}>
          <img src={FullLogo} alt="" style={{maxWidth: 230}} />
        </div>
        <LoginForm />
        <div className={'v-auth-separator'}>
          <Typography className={'px-5 text-textGrey font-bold'}>or</Typography>
        </div>
        <RegistrationForm />
      </Card>
    </div>
  );
};

export default AuthenticationPage;
