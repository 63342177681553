import Typography from 'legacy/components/elements/typography';
import RoundedImage from 'legacy/components/elements/roundedImage';

interface Iprops {
  avatar: string;
  accountName: string;
}

const Account = ({avatar, accountName}: Iprops) => {
  return (
    <div className={'grid gap-2 grid-flow-col items-center'} onClick={() => {}}>
      <Typography
        style={{
          marginRight: 22,
          font: 'normal normal 600 1.5rem/19px Noto Sans',
          letterSpacing: '0px',
          color: '#707070',
          opacity: 1,
        }}
        size={'md'}
        className={'text-textGrey capitalize font-bold hidden lg:block'}
      >
        {accountName}
      </Typography>
      <RoundedImage alt="" src={avatar} />
    </div>
  );
};

export default Account;
