import {Redirect, Route, Switch} from 'react-router-dom';
import NotFoundPage from 'components/NotFoundPage';
import {DashboardRoutes} from 'components/Dashboard';
import UserStateProvider from 'providers/userStateProvider';
import EventSourceProvider from 'providers/eventSourceProvider';
import ProfileStateProvider from 'providers/profileStateProvider';
import AuthenticationPage from 'legacy/reusables/AuthenticationPage';
import DashboardHomeStateProvider from 'providers/dashboardHomeStateProvider';


const App = () => (
  <UserStateProvider>
    <ProfileStateProvider>
      <EventSourceProvider>
        <Switch>
          <Route exact path="/">
            <Redirect to="/dashboard" />
          </Route>
          <Route exact path="/auth">
            <AuthenticationPage />
          </Route>
          <Route path="/dashboard">
            <DashboardHomeStateProvider>
              <DashboardRoutes />
            </DashboardHomeStateProvider>
          </Route>
          <Route path="*">
            <NotFoundPage />
          </Route>
        </Switch>
      </EventSourceProvider>
    </ProfileStateProvider>
  </UserStateProvider>
);

export default App;
