import {Switch, useRouteMatch, Route} from 'react-router-dom';
import NotFoundPage from 'components/NotFoundPage';
import ProtectedRoutes from 'components/ProtectedRoutes';
import {DashboardReportPage} from 'components/Dashboard';
import DashboardLayout from 'legacy/reusables/DashboardLayout';
import DashboardHomePage from 'legacy/reusables/DashboardHomePage';

const DashboardRoutes = () => {
  const match = useRouteMatch();

  return (
    <ProtectedRoutes>
      <DashboardLayout>
        <Switch>
          <Route exact path={match.url}>
            <DashboardHomePage />
          </Route>
          <Route exact path={`${match.url}/report`}>
            <DashboardReportPage />
          </Route>
          <Route path="*">
            <NotFoundPage />
          </Route>
        </Switch>
      </DashboardLayout>
    </ProtectedRoutes>
  );
};

export default DashboardRoutes;
