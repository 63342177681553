import React, {useState, useEffect} from 'react';
import './styles.css';
import {VIArrowCarrotDown} from 'legacy/assets/icons';

interface Iprops {
  value?: string;
  list: string[];
  disabled?: boolean;
  onChange?: (val: number) => void;
  style?: Object;
  className?: string;
  tabIndex?: number;
}

const DropDown = ({
  list,
  onChange,
  value,
  disabled = false,
  style = {},
  className}: Iprops) => {
  const [dropDownValue, setValue] = useState<string | undefined>(value);
  const [isOpen, toggle] = useState<boolean>(false);

  const handleChange = (newValue: string, key: number) => {
    setValue(newValue);
    if (onChange) {
      onChange(key);
    }
  };
  const handleToggle = () => {
    toggle(!isOpen);
  };

  useEffect(() => {
    setValue(value);
  }, [value]);

  return (
    <div
      className={`relative inline-block text-left ${className}`}
      style={style}
    >
      <div>
        <button
          type="button"
          className="drop-down select-border-color select-border-radius select-button inline-flex justify-between w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
          id="menu-button"
          disabled={disabled}
          aria-expanded="true"
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <span
            className="text-thin-blue"
            style={{maxWidth: 'calc(100% - 32px)'}}
          >
            {dropDownValue || 'None selected'}
          </span>
          <VIArrowCarrotDown />
        </button>
      </div>

      {isOpen && (
        <div
          className="w-full origin-top-right absolute right-0 mt-2 w-100 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex={-1}
          onClick={handleToggle}
        >
          <div
            className="py-2 overflow-y-scroll"
            role="none"
            style={{maxHeight: '20vh'}}
          >
            {list.map((item, key) => (
              <span
                key={key}
                onClick={() => handleChange(item, key)}
                className={`drop-down-item cursor-pointer text-gray-700 block px-4 py-2 text-sm ${
                  item === dropDownValue ? 'drop-down-item-active' : ''
                }`}
                role="menuitem"
                tabIndex={-1}
                id="menu-item-0"
              >
                {item}
              </span>
            ))}
            {list.length === 0 && (
              <span
                onClick={handleToggle}
                className="text-gray-700 block px-4 py-2 text-sm text-center cursor-pointer"
                role="menuitem"
                tabIndex={-1}
                id="menu-item-0"
              >
                No data to display
              </span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropDown;
