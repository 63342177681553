import React from 'react';
import './base.style.css';

const RoundedImage: React.FC<{
  src: string;
  alt: string;
  style?: { [key: string]: string | number };
}> = ({src, alt, style = {}}) => {
  return (
    <img
      style={{
        height: 57,
        width: 57,
        border: '1px solid #707070',
        borderRadius: '50%',
        ...style,
      }}
      src={src}
      alt={alt}
    />
  );
};

export default RoundedImage;
