import {ReactNode} from 'react';
import Header from 'legacy/shared/header';
import SideNav, {BottomNav} from 'legacy/shared/sideNav';
import './base.style.css';
import './layout.style.css';
import './components.style.css';
import './home.style.css';
import './home.components.style.css';
import './compare.style.css';
import './extra.style.css';
import './extra.two.style.css';

const DashboardLayout = ({children}: {children: ReactNode}) => (
  <div className="grid grid-cols-1 md:grid-cols-auto1fr ">
    <div className="hidden md:block v-dashboard-left">
      <SideNav />
    </div>
    <div>
      <div className="v-dashboard-top">
        <Header />
      </div>
      <div className="v-dashboard-content">
        <div className="container m-auto ">{children}</div>
      </div>
    </div>
    <div className="v-dashboard-bottom flex md:hidden">
      <BottomNav />
    </div>
  </div>
);

export default DashboardLayout;
