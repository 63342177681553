import Typography from 'legacy/components/elements/typography';
import Table from 'legacy/components/table';
import {representTableData} from 'legacy/util';
import {
  VIPh,
  VIPressure,
  VIChlorine,
  VITurbidity,
  VITemperature,
} from 'legacy/assets/icons';
import {Legends} from 'legacy/constants';
import {IchemicalList} from 'legacy/model/chemicalRecord';
import {useDashboardHomeState} from 'providers/dashboardHomeStateProvider';
import {useCallback, useMemo} from 'react';
import {TraitNameType, traits, TraitType} from 'utils/traits';
import {MapType} from 'utils/types';
import {ITelemetry} from 'models/telemetry';

const unitRate = (type: string) => <div className={`rate-is-${type}`} />;

const IconSize = {
  width: 30,
  height: 30,
};

const heading: Array<{
  title: string;
  description: string;
}> = [
  {
    title: 'Parameter',
    description:
      'A list of parameters for a given location with the acceptable range provided by the customer.',
  },
  {
    title: 'Value',
    description: 'The current value reading of each parameter.',
  },
  {
    title: 'Unit',
    description: 'The unit of measurement.',
  },
  {
    title: 'Trend',
    description:
      'The trend represents value the data is trending based on the time range selected.',
  },
];

const iconFunc = (name: string) => {
  switch (name) {
    case 'pressure':
      return <VIPressure className="svg-pressure-active" style={IconSize} />;
    case 'turbidity':
      return <VITurbidity className="svg-turbidity-active" style={IconSize} />;
    case 'chlorine':
      return <VIChlorine className="svg-chlorine-active" style={IconSize} />;
    case 'ph':
      return <VIPh className="svg-ph-active" style={IconSize} />;
    case 'temperature':
      return (
        <VITemperature className="svg-temperature-active" style={IconSize} />
      );
    default:
      return null;
  }
};

const Overview = () => {
  const {telemetries} = useDashboardHomeState();
  const {selectedTraitNames, setSelectedTraitNames} = useDashboardHomeState();

  const handleTraitNameToggle = useCallback((traitName: TraitNameType) => {
    const index = selectedTraitNames.indexOf(traitName);

    if (index < 0) {
      setSelectedTraitNames([...selectedTraitNames, traitName]);
    } else {
      const mutableArray = [...selectedTraitNames];
      mutableArray.splice(index, 1);
      setSelectedTraitNames(mutableArray);
    }
  }, [selectedTraitNames, setSelectedTraitNames]);

  const overviewData = useMemo(() => {
    const traitNameTelemetriesMap: MapType = {};

    telemetries.forEach((telemetry) => {
      if (!(telemetry.trait in traitNameTelemetriesMap)) {
        traitNameTelemetriesMap[telemetry.trait] = [];
      }

      traitNameTelemetriesMap[telemetry.trait].push(telemetry);
    });

    return Object.values(traits).map((trait: TraitType) => {
      const data = traitNameTelemetriesMap[trait.name];

      if (!data) {
        return {
          traitName: trait.name,
          value: null,
          trendDirection: null,
          trendValue: null,
          unit: trait.unitLabel,
        };
      };

      data.sort((a: ITelemetry, b: ITelemetry) =>
        (
          (new Date(a.collected_at).getTime()) -
          (new Date(b.collected_at).getTime())
        ));

      const values = data.map((t: ITelemetry) => t.properties.value);
      const latest = values.pop() as number;
      const valuesTotal = values.reduce(
          (sum: number, value: number) => (sum + value),
          0);
      const average = valuesTotal / values.length;
      const trendValue = (average - latest) / latest;

      return {
        traitName: trait.name,
        value: latest,
        trendDirection: trendValue < 0 ? 'down' : 'up',
        trendValue: Math.abs(trendValue).toFixed(2),
        unit: trait.unitLabel,
      };
    });
  }, [telemetries]);

  const columns = useMemo(
      () => representTableData(overviewData, (data) => ({
        Parameter: (
          <div className="flex items-center">
            <input
              type="checkbox"
              value={data.traitName}
              checked={selectedTraitNames.includes(data.traitName)}
              style={{marginRight: 10}}
              onChange={(e: any) => handleTraitNameToggle(e.target.value)}
            />
            {iconFunc(data.traitName)}
            <Typography className="font-bold text-textGrey ml-2">
              {Legends[data.traitName as IchemicalList].text}
            </Typography>
          </div>
        ),
        Value: <Typography className="font-bold">{data.value || '--'}</Typography>,
        Unit: data.unit || '--',
        Trend: (
          <div className="flex items-center">
            <span className="mr-2">{unitRate(data.trendDirection || '')}</span>
            {data.trendValue || '--'}
          </div>
        ),
      })), [overviewData, selectedTraitNames, handleTraitNameToggle]);

  return (
    <div className="v-card-table-heading  my-table v-card-table no-table-border-bottom">
      <Table heading={heading} columns={columns} />
    </div>
  );
};

export default Overview;
