import {useEffect, useMemo} from 'react';
import Typography from 'legacy/components/elements/typography';
import Card from 'legacy/components/elements/card';
import BarChart from 'legacy/components/barchart';
import Dropdown from 'legacy/components/dropdown';
import {useDashboardHomeState} from 'providers/dashboardHomeStateProvider';
import {TraitNameType, traits} from 'utils/traits';
import {useProfileState} from 'providers/profileStateProvider';
import {ICenter} from 'models/center';

const SensorLevels = () => {
  const {centers} = useProfileState();
  const {
    overviews,
    selectedOverviewTraitName,
    setSelectedOverviewTraitName,
  } = useDashboardHomeState();

  const traitNames: string[] = useMemo(
      () => Object.values(traits).map((t) => t.label), [],
  );

  const handleTraitNameChange = (index: number) => {
    const traitName = traitNames[index] as TraitNameType;

    setSelectedOverviewTraitName(traitName);
  };

  useEffect(() => {
    if (traitNames.length > 0) {
      setSelectedOverviewTraitName(traitNames[0] as TraitNameType);
    }
  }, [traitNames, setSelectedOverviewTraitName]);

  const barChartData = Object.entries(overviews).map(([centerId, average]) => {
    const center = centers.find((center) => center.id === centerId) as ICenter;

    return {
      value: average,
      label: center.name,
    };
  });

  return (
    <Card
      maxHeight={420}
      className="v-card lg:col-start-1 lg:col-end-3 large-card sensor-level-container"
    >
      <div className="card-header">
        <div className="card-header-section1">
          <Typography
            size="sub"
            className="text-normal-blue uppercase font-bold whitespace-nowrap"
          >
            OVERVIEW
          </Typography>
          <div
            className="flex items-center black-drop-down"
            style={{maxWidth: 460}}
          >
            <Dropdown
              tabIndex={5}
              style={{width: 200, zIndex: 100000}}
              list={traitNames}
              onChange={handleTraitNameChange}
              value={selectedOverviewTraitName || undefined}
            />
          </div>
        </div>
      </div>
      <div
        style={{width: '100%', height: '342px', minHeight: 0, minWidth: 0}}
        className="bar-chart"
      >
        {!barChartData.length ? null : (
          <BarChart
            data={barChartData}
            traitName={
              (selectedOverviewTraitName || '').toLocaleLowerCase() as TraitNameType
            }
          />
        )}
      </div>
    </Card>
  );
};

export default SensorLevels;
