import {ReactNode} from 'react';
import {Redirect} from 'react-router-dom';
import {useUserState} from 'providers/userStateProvider';

const ProtectedRoutes = ({children}: { children: ReactNode }) => {
  const {user} = useUserState();

  return user ? <>{children}</> : <Redirect to="/auth" />;
};

export default ProtectedRoutes;
