import {useState, useEffect, memo} from 'react';
import {useLocation} from 'react-router-dom';
import Account from 'legacy/components/account';
import logo from 'legacy/assets/images/logo-full.png';
import {VIPrint} from 'legacy/assets/icons';
import {get12hourTime} from 'legacy/util';
import Typography from 'legacy/components/elements/typography';
import {useUserState} from 'providers/userStateProvider';
import {useProfileState} from 'providers/profileStateProvider';
import './base.style.css';


const defaultAvatarImage = 'https://www.pngfind.com/pngs/m/610-6104451_image-placeholder-png-user-profile-placeholder-image-png.png';

const Today = memo(({profileName}: {profileName: string}) => {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setDate(new Date());
    }, 30 * 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div
      className="hidden lg:block current-date-time"
      style={{marginRight: 30}}
    >
      <Typography
        style={{
          textAlign: 'right',
          font: 'normal normal normal 1.4rem/16px Noto Sans',
          letterSpacing: 0,
          color: '#57585A',
          opacity: 1,
        }}
      >
        <span
          style={{
            marginRight: 10,
            font: 'normal normal 600 1.3rem/16px Noto Sans',
            color: '#57585A',
          }}
          className="current-date"
        >
          {profileName}
        </span>
        |{' '}
        <span
          style={{marginRight: 10, marginLeft: 10}}
          className="current-date"
        >
          {date.toLocaleDateString('us', {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
          })}
        </span>{' '}
        |{' '}
        <span style={{marginLeft: 10}} className="lowercase current-time">
          {get12hourTime()}
        </span>
      </Typography>
    </div>
  );
});

const Header = () => {
  const {user} = useUserState();
  const {profile} = useProfileState();
  const location = useLocation();
  const onReportPage = location.pathname.includes('/report');

  const accountName = `${user?.last_name || ''} ${user?.first_name || ''}`.trim() || 'Anonymous';

  return (
    <>
      <div
        className="sticky top-0 bg-bgBlack flex md:hidden"
        style={{minHeight: 86, zIndex: 2000000}}
      >
        <div className="v-header-top-nav flex h-auto">
          <img src={logo} alt="" style={{maxWidth: 126, width: '100%'}} />
          <div className="v-hamburger-container">
            <div className="bg-bgGrey w-10 mb-2" />
            <div className="bg-bgGrey w-10 mb-2" />
            <div className="bg-bgGrey w-10" />
          </div>
        </div>
      </div>
      <div className="px-0 md:px-10 py-0 md:py-5">
        <div className="bg-white flex items-center h-auto">
          <div className="container justify-between items-center h-auto grid header-container">
            <div
              className="flex flex-1 items-center"
              style={{zIndex: 1000001}}
            >
              {onReportPage && (
                <VIPrint
                  onClick={window.print}
                  className="cursor-pointer small-screen-hide"
                  style={{
                    width: 35,
                    height: 35,
                    color: 'grey',
                    marginLeft: 20,
                  }}
                />
              )}
            </div>
            <div className="flex flex-1 justify-end small-screen-hide">
              <div className="grid grid-flow-col gap-4 items-center">
                <Today profileName={profile?.name || ''} />
                <Account
                  avatar={defaultAvatarImage}
                  accountName={accountName}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
