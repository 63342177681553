import DashboardCompareCard from './DashboardCompareCard';
import DashboardInsightsCard from './DashboardInsightsCard';
import DashboardLocalTempCard from './DashboardLocalTempCard';
import DashboardCentersMapAndList from './DashboardCentersMapAndList';
import DashboardCentersOverviewCard from './DashboardCentersOverviewCard';

const DashboardHomePage = () => {
  return (
    <div>
      <div className="v-card-layout">
        <DashboardCompareCard />
        <DashboardCentersMapAndList />
        <DashboardCentersOverviewCard />
        <DashboardLocalTempCard />
        <DashboardInsightsCard />
      </div>
    </div>
  );
};

export default DashboardHomePage;
