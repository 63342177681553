// import request from './request';
import axios from 'axios';
/* eslint-disable */
interface Imain {
  temp: number;
  feels_like: number;
  temp_min: number;
  temp_max: number;
  pressure: number;
  sea_level: number;
  grnd_level: number;
  humidity: number;
  temp_kf: number;
}

interface Iweather {
  id: number;
  main: string;
  description: string;
  icon: string;
}
interface Iclouds {
  all: number;
}
interface Iwind {
  speed: number;
  deg: number;
  gust: number;
}

interface Isys {
  pod: string;
}
interface IHour3 {
  dt: number;
  main: Imain;
  weather: Iweather[];
  clouds: Iclouds;
  wind: Iwind;
  visibility: number;
  pop: number;
  sys: Isys;
  dt_txt: string;
}
interface Iforcast {
  cod: string;
  message: number;
  cnt: number;
  list: IHour3[];
}
/* eslint-enable */

export const getForecast = ({lat, lon}: { lat: number; lon: number }) =>
  axios.request<Iforcast>({
    url: `https://api.openweathermap.org/data/2.5/forecast?lat=${lat}&lon=${lon}&appid=${process.env.REACT_APP_OPEN_WEATHER_API_KEY}`,
    method: 'GET',
  });
