import {useState, useEffect, useContext, createContext, ReactNode} from 'react';
import {ICenter} from 'models/center';
import {fetchProfile} from 'api/profile';
import {useUserState} from 'providers/userStateProvider';
import {IProfile, IFetchProfileResponseData} from 'models/profile';

interface IProfileStateContext {
  profile: IProfile | null,
  centers: ICenter[],
};

export const profileStateContextDefault: IProfileStateContext = {
  profile: null,
  centers: [],
};

export const ProfileStateContext = createContext(profileStateContextDefault);

export const useProfileState = () => useContext(ProfileStateContext);

const ProfileStateProvider = ({children}:{children: ReactNode}) => {
  const {user} = useUserState();
  const [profile, setProfile] = useState<IProfile | null>(null);
  const [centers, setCenters] = useState<ICenter[]>([]);

  useEffect(() => {
    if (!user) {
      setCenters([]);
    } else {
      fetchProfile().then((response) => {
        const {data: {profile: {centers, ...profile}}} = (
          response as {data: IFetchProfileResponseData}
        );

        setProfile(profile);
        setCenters(centers);
      });
    }
  }, [user]);

  const value: IProfileStateContext = {
    profile,
    centers,
  };

  return (
    <ProfileStateContext.Provider value={value}>
      {children}
    </ProfileStateContext.Provider>
  );
};

export default ProfileStateProvider;
