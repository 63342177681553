import React, {FC, HTMLAttributes, InputHTMLAttributes} from 'react';
import caretDown from 'legacy/assets/icons/caretDown.svg';
import Typography from './typography';

type SelectT = FC<
  InputHTMLAttributes<HTMLSelectElement> & {
    suffix?: any;
    error?: boolean;
    borderless?: boolean;
    value?: string | { label: string; value: any };
    options: Array<{ label: string; value: any }>;
  }
>;
export type CheckBoxT = FC<
  HTMLAttributes<HTMLInputElement> & { label?: string }
>;

export type InPutT = FC<
  InputHTMLAttributes<HTMLInputElement> & {
    error?: boolean;
    suffix?: any;
  }
> & {
  Select: SelectT;
  CheckBox: CheckBoxT;
};

const Input: InPutT = ({suffix, error, style, ...props}) => {
  return (
    <div
      className={`v-input`}
      style={{border: error ? '1px solid red' : undefined, ...style}}
    >
      <input {...props} className={`v-input-input ${suffix ? 'mr-3' : ''}`} />
      {suffix && <div>{suffix}</div>}
    </div>
  );
};

const Select: SelectT = ({
  options,
  name,
  placeholder,
  borderless,
  value,
  error,
  ...rest
}) => {
  const selectedValue = typeof value === 'string' ? value : value?.value;

  return (
    <div
      className={`v-input`}
      style={{...rest.style, border: error ? '1px solid red' : undefined}}
    >
      <select
        placeholder={placeholder}
        {...rest}
        value={selectedValue}
        className={'v-input-input appearance-none'}
        style={{
          background: `url(${caretDown}) no-repeat`,
          backgroundPosition: 'calc(100% - 0px) 50%',
          paddingRight: 30,
        }}
        onChange={() => {}}
      >
        <option value={''} disabled>
          {placeholder ?? 'Select...'}
        </option>
        {options.map(({value, label}) => (
          <option value={value} key={value + label}>
            {label}
          </option>
        ))}
      </select>
    </div>
  );
};

const Checkbox: CheckBoxT = ({label, onChange}) => {
  return (
    <div className={'flex items-center'}>
      <input type="checkbox" id={label} onChange={onChange} />
      {label && (
        <label htmlFor={label} className={'ml-2'}>
          <Typography size={'sm'} className={'text-textGrey font-bold'}>
            {label}
          </Typography>
        </label>
      )}
    </div>
  );
};

Input.Select = Select;
Input.CheckBox = Checkbox;

export default Input;
