import {FC} from 'react';
import Typography from './elements/typography';

type tableHeadingT = Array<{
  title: string;
  description: string;
}>;

type tableColumnT = {
  [key: string]: any;
};

const Table: FC<{ columns: tableColumnT; heading: tableHeadingT }> = ({
  columns,
  heading,
}) => {
  return (
    <div className="overflow-auto h-full my-table">
      <table>
        <thead>
          <tr className={'Table__tr-heading'}>
            {heading.map((headerData, i) => (
              <th className={'text-left'} key={i}>
                <Typography className={'text-textGrey capitalize'}>
                  {headerData.title}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {columns.map((el: { [s: string]: any }, index: number) => (
            <tr className={'Table__tr-column'} key={index}>
              {heading.map((headerData, i) => (
                <td
                  className={'text-left'}
                  key={i}
                  style={
                    index === 0 ?
                      {
                        paddingTop: '20px',
                      } :
                      {}
                  }
                >
                  {el[headerData.title] || '--'}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
