import {
  VIChlorine,
  VIPh,
  VIConductivity,
  VITemperature,
  VITurbidity,
  VIPressure,
} from 'legacy/assets/icons';
import {Ioption} from 'legacy/model/home';
import {ISensorStatus} from 'legacy/model/status';
import {IchemicalList} from 'legacy/model/chemicalRecord';
import {MapType} from 'utils/types';

export const compareOptions: Ioption = {
  'Last 1 hour': '5T',
  'Last 2 hours': '5T',
  'Last 4 hours': '5T',
  'Last 8 hours': '1H',
  'Last 12 hours': '1H',
  'Last 24 hours': '1H',
  'Last 2 days': '2H',
  'Last 3 days': '2H',
  'Last 7 days': '1D',
  'Last 15 days': '1D',
  'Last 30 days': '1D',
  'Last 2 months': '2D',
  'Last 3 month': '7D',
  'Last 12 month': '1M',
};

export const chemicalColors = [
  '#00A8D7',
  '#D9683C',
  '#019293',
  '#74A242',
  '#F4A335',
  '#C83D5C',
];

// eslint-disable-next-line
export const chemicalColorsObject: { [k in IchemicalList]: string } = {
  'chlorine': '#00A8D7',
  'water temperature': '#C83D5C',
  'ph': '#D9683C',
  'turbidity': '#019293',
  'pressure': '#F4A335',
  'internal pressure': '#F4A335',
  'conductivity': '#F4A335',
  'pH': '#D9683C',
};

export const chemicals: IchemicalList[] = [
  'chlorine',
  'ph',
  'turbidity',
  'pressure',
  'water temperature',
];

// Standard Data

export const Legends: MapType<MapType> = {
  'chlorine': {
    text: 'Chlorine (0.2 - 2 mg/L)',
    Icon: VIChlorine,
    theme: 'svg-chlorine-active',
    id: 'chlorine',
  },
  'temperature': {
    text: `Temperature (4° - 25° C)`,
    Icon: VITemperature,
    theme: 'svg-temperature-active',
    id: 'temperature',
  },
  'turbidity': {
    text: 'Turbidity (0 - 1 NTU)',
    Icon: VITurbidity,
    theme: 'svg-turbidity-active',
    id: 'turbidity',
  },
  'ph': {
    text: 'pH (6.5 - 8.5)',
    Icon: VIPh,
    theme: 'svg-ph-active',
    id: 'ph',
  },
  'pH': {
    text: 'pH (6.5 - 8.5)',
    Icon: VIPh,
    theme: 'svg-ph-active',
    id: 'pH',
  },
  'conductivity': {
    text: 'Conductivity (200 - 800 µS/cm)',
    Icon: VIConductivity,
    theme: 'svg-conductivity-active',
    id: 'conductivity',
  },
  'pressure': {
    text: 'Pressure (35 - 80 psi)',
    Icon: VIPressure,
    theme: 'svg-conductivity-active',
    id: 'pressure',
  },
  'internal pressure': {
    text: 'Pressure (20psi - 80psi)',
    Icon: VIPressure,
    theme: 'svg-conductivity-active',
    id: 'pressure',
  },
};

// Amarillo Data
// export const Legends: IL = {
//   'chlorine': {
//     text: 'Chlorine (0 - 2 mg/L)',
//     Icon: VIChlorine,
//     theme: 'svg-chlorine-active',
//     id: 'chlorine',
//   },
//   'water temperature': {
//     text: `Temperature (0° - 30° C)`,
//     Icon: VITemperature,
//     theme: 'svg-temperature-active',
//     id: 'temperature',
//   },
//   'turbidity': {
//     text: 'Turbidity (0 - 20 NTU)',
//     Icon: VITurbidity,
//     theme: 'svg-turbidity-active',
//     id: 'turbidity',
//   },
//   'ph': {
//     text: 'pH (0 - 14)',
//     Icon: VIPh,
//     theme: 'svg-ph-active',
//     id: 'ph',
//   },
//   'pH': {
//     text: 'pH (6.5 - 8.5)',
//     Icon: VIPh,
//     theme: 'svg-ph-active',
//     id: 'pH',
//   },
//   'conductivity': {
//     text: 'Conductivity (0 - 1000 us/cm)',
//     Icon: VIConductivity,
//     theme: 'svg-conductivity-active',
//     id: 'conductivity',
//   },
//   'pressure': {
//     text: 'Pressure (20psi - 80psi)',
//     Icon: VIPressure,
//     theme: 'svg-conductivity-active',
//     id: 'pressure',
//   },
//   'internal pressure': {
//     text: 'Pressure (20psi - 80psi)',
//     Icon: VIPressure,
//     theme: 'svg-conductivity-active',
//     id: 'pressure',
//   },
// };

export const demoMapStyles = [
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e9e9e9',
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5',
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#ffffff',
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#ffffff',
      },
      {
        lightness: 29,
      },
      {
        weight: 0.2,
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
      {
        lightness: 18,
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5',
      },
      {
        lightness: 21,
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#dedede',
      },
      {
        lightness: 21,
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: '#ffffff',
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        saturation: 36,
      },
      {
        color: '#333333',
      },
      {
        lightness: 40,
      },
    ],
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [
      {
        color: '#f2f2f2',
      },
      {
        lightness: 19,
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#fefefe',
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#fefefe',
      },
      {
        lightness: 17,
      },
      {
        weight: 1.2,
      },
    ],
  },
];

export const statusResponseData: ISensorStatus = {
  data: {
    performance: {
      flow: {
        status: true,
        value: 1,
        since: new Date(),
      },
      pressure: {
        status: true,
        value: 1,
        since: new Date(),
      },
    },
    communication: {
      online: {
        status: true,
        value: 1,
        since: new Date(),
      },
      transmission: {
        status: true,
        value: 1,
        since: new Date(),
      },
    },
  },
};
